import * as React from 'react'

import { Api, Autorenew, SupervisorAccountOutlined } from '@mui/icons-material'
import {
  Box,
  Collapse,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import type { Theme } from '@mui/material'

type CardProps = {
  title: string
  body: string
  icon: React.ReactNode
  onClick: () => void
  isSelected: boolean
}

const Card = ({
  title,
  body,
  icon,
  onClick,
  isSelected,
}: CardProps) => (
  <Paper
    variant={isSelected ? 'elevation' : 'outlined'}
    elevation={isSelected ? 12 : undefined}
    onClick={onClick}
    sx={{
      p: 3,
      cursor: 'pointer',
    }}
  >
    <Stack
      direction='row'
      alignItems='center'
      spacing={2}
    >
      <Stack
        direction='column'
        spacing={1}
      >
        <Typography
          variant='h5'
          component='h3'
          fontWeight={500}
        >
          {title}
        </Typography>
        <Collapse in={isSelected}>
          <Typography
            variant='body1'
            component='h4'
            fontWeight={300}
          >
            {body}
          </Typography>
        </Collapse>
      </Stack>
      {icon}
    </Stack>
  </Paper>
)

const CardsDisplay = () => {
  const [selectedCard, setSelectedCard] = React.useState(1)

  return (
    <Grid
      container
      textAlign={useMediaQuery((theme: Theme) => theme.breakpoints.up('md')) ? 'left' : 'center'}
      justifyContent='center'
      alignItems='center'
      columnSpacing={6}
      rowSpacing={6}
    >
      <Grid
        item
        xs={12}
        md={5}
        alignItems='center'
        justifyContent='center'
        sx={{ gap: 1 }}
      >
        <Stack spacing={2}>
          <Card
            title='Controla todo desde una sola plataforma'
            body='Perfiles de administradores para que tú y tus equipos tengan el control absoluto'
            icon={<SupervisorAccountOutlined fontSize='large' />}
            onClick={() => setSelectedCard(1)}
            isSelected={selectedCard === 1}
          />
          <Card
            title='Digitaliza tu operación en menos de una semana'
            body='Ofréceles una plataforma completa a tus usarios en menos de una semana'
            icon={<Autorenew fontSize='large' />}
            onClick={() => setSelectedCard(2)}
            isSelected={selectedCard === 2}
          />
          <Card
            title='Marca blanca y API'
            body='Tu logo, marca y colores en los productos financieros que quieras construir'
            icon={<Api fontSize='large' />}
            onClick={() => setSelectedCard(3)}
            isSelected={selectedCard === 3}
          />
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
      >
        {(selectedCard === 1) && (
          <StaticImage
            src='../../images/landing/features-card-1.png'
            alt='Dashboard con gráficos de balance contable'
            loading='eager'
            placeholder='none'
          />
        )}
        {(selectedCard === 2) && (
          <StaticImage
            src='../../images/landing/features-card-2.png'
            alt='Dashboard con datos de identidad del usuario'
            loading='eager'
            placeholder='none'
          />
        )}
        {(selectedCard === 3) && (
          <StaticImage
            src='../../images/landing/features-card-3.png'
            alt='Documentación de la API de la plataforma'
            loading='eager'
            placeholder='none'
          />
        )}
      </Grid>
    </Grid>
  )
}

const FeaturesSection = () => (
  <Box
    id='features'
    py={10}
  >
    <Container maxWidth='lg'>
      <Stack
        spacing={2}
        pb={4}
      >
        <Typography
          variant='h3'
          component='h2'
          fontWeight={500}
          textAlign='center'
        >
          Una suite financiera completa
        </Typography>
        <Typography
          variant='h6'
          component='h2'
          fontWeight={300}
          textAlign='center'
        >
          Desde el onboarding de usuarios nuevos hasta los reportes que ven
          tus clientes, las nuevas generaciones quieren productos digitales.
        </Typography>
      </Stack>
      <CardsDisplay />
    </Container>
  </Box>
)

export default FeaturesSection
