import * as React from 'react'

import {
  AddPhotoAlternateOutlined,
  AssuredWorkloadOutlined,
  Autorenew,
  LoginOutlined,
  PolicyOutlined,
  SpaceDashboardOutlined,
} from '@mui/icons-material'
import { Box, Container, Grid, Paper, Stack, Typography } from '@mui/material'

type CardProps = {
  title: React.ReactNode
  body: React.ReactNode
  icon: React.ReactNode
}

const Card = ({
  title,
  body,
  icon,
}: CardProps) => (
  <Grid
    item
    xs={12}
    sm={6}
    md={4}
  >
    <Paper
      variant='outlined'
      sx={(theme) => ({
        height: '100%',
        borderColor: theme.palette.mode === 'light' ? 'text.primary' : 'primary.dark',
        bgcolor: theme.palette.mode === 'light' ? 'primary.light' : 'action.hover',
      })}
    >
      <Stack
        direction='column'
        alignItems='center'
        spacing={2}
        sx={{ p: 2 }}
      >
        {icon}
        <Typography
          variant='h5'
          component='h3'
          fontWeight={500}
          textAlign='center'
        >
          {title}
        </Typography>
        <Typography
          variant='body1'
          component='h4'
          fontWeight={300}
          textAlign='center'
        >
          {body}
        </Typography>
      </Stack>
    </Paper>
  </Grid>
)

const DetailedFeaturesSection = () => {
  return (
    <Box
      py={10}
      bgcolor='action.selected'
    >

      <Container
        maxWidth='lg'
        sx={{ py: 10 }}
      >
        <Stack
          spacing={2}
          pb={4}
        >
          <Typography
            variant='h3'
            component='h2'
            fontWeight={500}
            textAlign='center'
          >
            Soluciones a tu medida
          </Typography>
          <Typography
            variant='h6'
            component='h2'
            fontWeight={300}
            textAlign='center'
          >
            Atrae más clientes y brinda una experiencia financiera inolvidable:
            facilita el registro automático de usuarios y ofrece productos
            financieros excepcionales.
          </Typography>
        </Stack>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={3}
        >
          <Card
            title='Onboarding'
            body='Automatiza el registro de nuevos usuarios.'
            icon={<Autorenew fontSize='large' />}
          />
          <Card
            title='Marca Blanca'
            body='El logo de tu empresa por arriba, Guita por debajo.'
            icon={<AddPhotoAlternateOutlined fontSize='large' />}
          />
          <Card
            title='KYC'
            body='Compliance completo de tus usuarios en tiempo real.'
            icon={<AssuredWorkloadOutlined fontSize='large' />}
          />
          <Card
            title='Reportería y Dashboards'
            body='Automatiza los reportes que ven tus usuarios.'
            icon={<SpaceDashboardOutlined fontSize='large' />}
          />
          <Card
            title='AML'
            body='Protección y detección contra el lavado de activos.'
            icon={<PolicyOutlined fontSize='large' />}
          />
          <Card
            title='Log-ins'
            body='Accesos de tus usuarios a sus plataformas.'
            icon={<LoginOutlined fontSize='large' />}
          />
        </Grid>
      </Container>
    </Box>
  )
}

export default DetailedFeaturesSection
