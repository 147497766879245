import * as React from 'react'

import { Button, Container, Stack, Typography } from '@mui/material'

const HeroSection = () => {
  return (
    <Container
      maxWidth='lg'
      sx={{
        height: '90vh',
        maxHeight: '800px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Stack
        direction='column'
        spacing={4}
        px={1}
        py='5'
      >
        <Typography
          textAlign='center'
          variant='h1'
          component='h1'
          fontWeight={800}
        >
          Digitaliza, conviértete en&nbsp;
          <span
            style={{
              background: 'var(--color-mainTitleGradient)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Fintech
          </span>
        </Typography>
        <Typography
          textAlign='center'
          variant='h5'
          component='h2'
          fontWeight={300}
        >
          Guita es la solución tecnológica todo-en-uno para empresas financieras.
          Crea tu App en semanas sin un equipo de desarrolladores, simple.
        </Typography>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          spacing={2}
        >
          <Button
            variant='contained'
            size='large'
            href='#features'
          >
            Ver más
          </Button>
          <Button
            id='cta-hero'
            href='/contacto'
            variant='contained'
            size='large'
            sx={{
              bgcolor: 'primary.light',
              color: 'common.black',
            }}
          >
            Empezar
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
}

export default HeroSection
