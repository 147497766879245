import * as React from 'react'

import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material'

import type { TypographyProps } from '@mui/material'

const FooterText = ({
  align = 'justify',
  children,
}: TypographyProps) => (
  <Typography
    align={align}
    component='div'
    variant='caption'
    gutterBottom
  >
    {children}
  </Typography>
)

export const Footer = () => (
  <Box
    sx={{
      bgcolor: 'grey.900',
      color: 'grey.400',
    }}
  >
    <Container
      maxWidth='lg'
      sx={{
        display: 'flex',
        pt: 3,
        pb: 2,
      }}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={3}
        >
          <Stack
            spacing={1}
            sx={(theme) => ({
              textAlign: 'center',
              [theme.breakpoints.up('md')]: {
                textAlign: 'left',
              },
            })}
          >
            <Link
              href='//docs.guita.cl'
              target='_blank'
              rel='noopener'
              color='inherit'
              underline='none'
              fontWeight={500}
            >
              Documentación API
            </Link>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
        >
          <FooterText>
            En Guita, somos una plataforma financiera en la nube que ofrece un
            innovador software as a service (SaaS) diseñado para acercar la
            tecnología financiera tradicional y blockchain a personas y empresas
            en América Latina. Nuestra misión es impulsar el crecimiento y la
            eficiencia de nuestros clientes, brindándoles soluciones ágiles y
            seguras que se adaptan a sus necesidades.
          </FooterText>
          <FooterText align='center'>
            Cualquier duda adicional escríbenos a contacto@guita.cl
          </FooterText>
          <FooterText align='center'>
            San Patricio 4298, Oficina 11, Vitacura, Santiago, Chile.
          </FooterText>
          <FooterText align='center'>
            &copy; Guita {new Date().getFullYear()}
          </FooterText>
        </Grid>
      </Grid>
    </Container>
  </Box>
)

export default Footer
