import * as React from 'react'

import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

const DescriptionSection = () => (
  <Box
    id='description'
    py={8}
    bgcolor='action.selected'
  >
    <Container maxWidth='lg'>
      <Grid
        container
        direction='row'
        rowSpacing={8}
        columnSpacing={10}
        justifyContent='center'
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          display='flex'
          direction='column'
          alignItems='center'
          justifyContent='center'
          gap={3}
        >
          <Typography
            variant='h3'
            component='h2'
            fontWeight={500}
            textAlign='center'
          >
            Crea tu plataforma financiera con la marca de tu empresa
          </Typography>
          <Typography
            variant='h6'
            component='h2'
            fontWeight={300}
            textAlign='center'
          >
            No necesitas un equipo de desarrolladores ni esperar meses por tu producto.
            Lo tienes en menos de una semana 🌱
          </Typography>
          <Button
            variant='contained'
            size='large'
            href='/contacto'

          >
            Empezar
          </Button>
        </Grid>
        <Grid
          item
          xs={11}
          sm={6}
          md={4}
          display='flex'
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <StaticImage
            src='../../images/landing/description-ilustration.png'
            alt='Ejemplo de aplicación Guita'
            loading='eager'
            placeholder='none'
            width={600}
            quality={90}
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
)

export default DescriptionSection
