import * as React from 'react'

import { Box, Container, Paper, Stack, Typography } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

const Flags = () => (
  <Stack
    direction='row'
    spacing={2}
    alignItems='center'
    justifyContent='space-evenly'
    sx={{ pb: 6 }}
  >
    <StaticImage
      src='../../images/landing/flags/chile.png'
      alt='Bandera de Chile'
      width={64}
      quality={90}
    />
    <StaticImage
      src='../../images/landing/flags/argentina.png'
      alt='Bandera de Argentina'
      width={64}
      quality={90}
    />
    <StaticImage
      src='../../images/landing/flags/peru.png'
      alt='Bandera de Perú'
      width={64}
      quality={90}
    />
    <StaticImage
      src='../../images/landing/flags/colombia.png'
      alt='Bandera de Colombia'
      width={64}
      quality={90}
    />
    <StaticImage
      src='../../images/landing/flags/mexico.png'
      alt='Bandera de México'
      width={64}
      quality={90}
    />
  </Stack>
)

const Testimony = () => (
  <Paper
    variant='outlined'
    sx={{ borderColor: 'primary.dark' }}
  >
    <Stack
      alignItems='center'
      spacing={1}
      p={3}
    >
      <StaticImage
        src='../../images/landing/aldo.jpg'
        alt='Aldo Schenone'
        width={100}
        quality={90}
        style={{ borderRadius: '50%' }}
      />
      <Typography
        variant='h6'
        component='p'
        textAlign='center'
      >
        ¡Una experiencia increíble!
      </Typography>
      <Typography
        textAlign='justify'
        pb={1}
      >
        Gracias a Guita, lanzamos Palgo Wallet en solo una semana. Su proceso de
        onboarding fue simple y veloz, permitiéndonos empezar a operar
        rápidamente. Con su plataforma, creamos productos financieros para
        nuestros usuarios en tiempo récord. Quedamos impresionados con la
        calidad y velocidad de las soluciones que Guita nos proporcionó.
        Recomendaría Guita a cualquier fintech que quiera acelerar su éxito.
      </Typography>
      <Typography
        variant='h6'
        component='p'
        fontWeight={500}
        textAlign='center'
        lineHeight={1}
      >
        Aldo Schenone
      </Typography>
      <Typography
        fontWeight={400}
        textAlign='center'
        color='text.secondary'
      >
        CEO & Co-founder Palgo Wallet
      </Typography>
    </Stack>
  </Paper>
)

const TestimoniesSection = () => {
  return (
    <Box
      py={10}
    >
      <Container maxWidth='lg'>
        <Stack
          alignItems='center'
          spacing={2}
          pb={4}
        >
          <Typography
            variant='h3'
            component='h2'
            fontWeight={500}
            textAlign='center'
          >
            Utilizado en 5 países de Latinoamérica
          </Typography>
          <Typography
            variant='h6'
            component='h2'
            fontWeight={300}
            textAlign='center'
          >
            Revoluciona y crea productos financieros para los mercados emergentes de LATAM.
          </Typography>
          <Container
            maxWidth='md'
          >
            <Flags />
            <Testimony />
          </Container>
        </Stack>
      </Container>
    </Box>
  )
}

export default TestimoniesSection
