import * as React from 'react'

import { Button } from '@mui/material'

import { SeoHeaders } from 'shared/components'

import DescriptionSection from '../components/landing/description_section'
import DetailedFeaturesSection from '../components/landing/detailed_features_section'
import FeaturesSection from '../components/landing/features_section'
import Footer from '../components/landing/footer'
import HeroSection from '../components/landing/hero_section'
import TestimoniesSection from '../components/landing/testimonies_section'
import NavBar from '../components/nav_bar'

const IndexPage = () => {
  return (
    <React.Fragment>
      <SeoHeaders
        title='Digitaliza, conviértete en Fintech'
        description={(
          'Digitaliza tu empresa con Guita: solución todo-en-uno para'
          + ' onboarding, KYC, AML, whitelabel y más. ¡Conviértete en Fintech'
          + ' de manera simple y eficiente!'
        )}
        schemaMarkup={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          'name': 'Landing Page de Guita',
          'url': 'https://guita.cl',
          'description': 'Digitaliza tu empresa con Guita: solución todo-en-uno para'
          + ' onboarding, KYC, AML, whitelabel y más. ¡Conviértete en Fintech'
          + ' de manera simple y eficiente!',
          'publisher': {
            '@type': 'Organization',
            'name': 'Guita',
            'logo': 'https://guita.cl/images/mail-header.png',
          },
        }}
      />
      <NavBar maxWidth='lg'>
        <Button
          href='/articulos/'
          variant='text'
          size='large'
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          Artículos
        </Button>
        <Button
          id='cta-header'
          href='/contacto'
          variant='contained'
          sx={{
            bgcolor: 'primary.light',
            color: 'common.black',
          }}
        >
          Empezar
        </Button>
      </NavBar>
      <HeroSection />
      <DescriptionSection />
      <FeaturesSection />
      <DetailedFeaturesSection />
      <TestimoniesSection />
      <Footer />
    </React.Fragment>
  )
}

export default IndexPage
